module.exports = {
  aside: {
    dashboard: {
      home: 'Full oversikt'
    },
    tasks: {
      index: 'Oppgavebehandling'
    },
    products: {
      all: 'Alle produktene',
      create: 'Legg til produkt',
      sync: 'Synkroniser produkter'
    },
    templates: {
      all: 'Alle templatene',
      create: 'Legg til templat'
    },
    customers: {
      all: 'Alle kundene',
      create: 'Legg til kunde'
    },
    users: {
      all: 'Alle brukere',
      create: 'Legg til bruker'
    },
    logs: {
      index: 'Systemlogg'
    }
  }
}
