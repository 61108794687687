// Global translations
//
// Keep everything in lowercase, capitalize with filters in views.
// Only single words in this global file - all sentences in individual files
//
// Paramters
// In view: trans('global.translation_key', { key: 2 })
// In trans: translation_key: 'This number will be dynamically inserted -> :key'
//
// Plurals
// In view: tranc_choice('global.translation_key', 1)
// In trans: translation_key: '{1} This will appear when transChoice uses param 1 | {*} Every other number'

/* eslint-disable max-len */
module.exports = {
  register: '{1}registrer|{*}registreringer',
  email: '{1}epost|{*}eposter',
  password: 'passord',
  user: '{0}bruker|{1}brukeren|{*}brukere',
  superuser: '{0}superbruker|{1}superbrukeren|{*}superbrukere',
  shortcut: '{1}snarvei|{*}snarveier',
  firstname: '{0}fornavn|{1}fornavnet|{*}fornavn',
  middlename: '{0}mellomnavn|{1}mellomnavnet|{*}mellomnavn',
  lastname: '{0}etternavn|{1}etternavnet|{*}etternavn',
  phone: '{1}telefon|{*}telefoner',
  avatar: '{1}avatar|{*}avatarer ',
  profileimage: '{1}profilbilde|{*}profilbilder',
  image: '{0}bilde|{1}bilde|{*}bilder',
  group: '{0}gruppe|{1}gruppen|{*}grupper',
  groupname: '{1}gruppenavn|{*}gruppenavn',
  description: '{0}beskrivelse|{1}beskrivelsen|{*}beskrivelser',
  save: '{1}lagre|{*}lagre',
  name: '{0}navn|{1}navnet|{*}navnene',
  action: '{0}handling|{1}handlingen|{*}handlinger',
  dashboard: '{0}dashboardet|{1}dashboard|{*}dashboard',
  profile: '{1}profil|{*}profiler',
  account: '{0}konto|{1}kontoen|{*}kontoer',
  role: '{1}rolle|{*}roller',
  settings: '{1}innstilling|{*}innstillinger',
  edit: '{0}rediger|{1}redigere|{*}redigerer',
  rights: '{1}rettighet|{*}rettigheter',
  admin: '{1}administrator|{*}administratorer',
  tools: '{1}verktøy|{*}verktøy',
  synchronize: '{1}synkroniser|{*}synkroniser',
  time: '{1}kl.|{*}kl.',
  system: '{0}system|{1}systemet|{*}systemer',
  events: '{0}hendelse|{1}hendelsen|{*}hendelser',
  success: '{0}suksess|{1}suksessen|{*}suksesser',
  error: '{0}feil|{1}feilen|{*}feil',
  warning: '{0}advarsel|{1}advarselen|{*}advarslene',
  info: '{0}info|{1}infoen|{*}infoene',
  access: '{0}tilgang|{1}tilgangen|{*}tilgangene',
  hello: '{0}hei|{1}heien|{*}heiene',
  frontend: '{0}frontend|{1}frontenden|{*}frontendene',
  backend: '{0}backend|{1}backenden|{*}backendene',
  both: '{0}begge|{1}begge|{*}begge',
  disable: '{0}deaktiver|{1}deaktivere|{*}deaktiverer',
  delete: '{0}slett|{1}slette|{*}sletter',
  deleted: '{0}slette|{1}slettet|{*}slettede',
  cancel: '{0}avbryt|{1}avbryter|{*}avbryte',
  activate: '{0}aktiver|{1}aktiverer|{*}aktivere',
  project: '{0}prosjekt|{1}prosjektet|{*}prosjekter',
  company: '{0}firma|{1}firmaet|{*}firmaer',
  connection: '{0}tilkobling|{1}tilkoblingen|{*}tilkoblinger',
  connect: '{0}koble|{1}kobler|{*}koble',
  complete: '{0}fullfør|{1}fullfører|{*}fullfør',
  team: '{0}team|{1}teamet|{*}teams',
  member: '{0}medlem|{1}medlemmet|{*}medlemmer',
  active: '{0}aktiv|{1}aktiv|{*}aktive',
  private: '{0}privat|{1}privat|{*}private',
  developer: '{0}utvikler|{1}utvikleren|{*}utviklere',
  permission: '{0}tillatelse|{1}tillatelsen|{*}tillatelser',
  look: '{0}se|{1}ser|{*}seende',
  create: '{0}lag|{1}lage|{*}lager',
  created: '{0}opprette|{1}opprettet|{*}opprettelser',
  welcome: '{0}velkommen|{1}velkommen|{*}velkomne',
  owner: '{0}eier|{1}eieren|{*}eierene',
  invitation: '{0}invitasjon|{1}invitasjonen|{*}invitasjonene',
  accept: '{0}aksepter|{1}aksepten|{*}akseptene',
  decline: '{0}avslå|{1}avslåelsen|{*}avslåelsene',
  yes: '{0}ja|{1}ja|{*}ja',
  no: '{0}nei|{1}nei|{*}nei',
  template: '{0}templat|{1}templatet|{*}templater',
  product: '{0}produkt|{1}produktet|{*}produkter',
  customer: '{0}kunde|{1}kunden|{*}kunder',
  session: '{0}økt|{1}økten|{*}økter',
  title: '{0}tittel|{1}tittelen|{*}titler',
  date: '{0}dato|{1}datoen|{*}datoer',
  successful: '{0}vellykket|{1}vellykket|{*}vellykkede',
  unknown: '{0}ukjent|{1}ukjent|{*}ukjente',
  id: '{0}id|{1}iden|{*}ider',
  details: '{0}detalj|{1}detaljen|{*}detaljer',
  status: '{0}status|{1}statusen|{*}statuser',
  all: '{0}alle|{1}alle|{*}alle',
  loading: '{0}last|{1}lasten|{*}laster',
  task: '{0}oppgave|{1}oppgaven|{*}oppgaver',
  log: '{0}logg|{1}loggen|{*}logger',
  position: '{0}posisjon|{1}posisjonen|{*}posisjoner',
  order: '{0}rekkefølge|{1}rekkefølgen|{*}rekkefølger',
  standard: '{0}standard|{1}standard|{*}standard',
  executed: '{0}utført|{1}utført|{*}utførte',
  render: '{0}render|{1}renderen|{*}rendere',
  layer: '{0}lag|{1}laget|{*}lagene',
  earlier: '{0}tidligere|{1}tidligere|{*}tidligere',
  updated: '{0}oppdatert|{1}oppdatert|{*}oppdatert',
  from: '{0}fra|{1}fra|{*}fra',
  to: '{0}til|{1}til|{*}til',
  menu: '{0}meny|{1}menyen|{*}menyer'
}
